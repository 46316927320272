import './new-pma.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import {Alert, InputBase, Snackbar} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Input from "../../../atoms/input";
import apiClient from "../../../../utils/apiClient";
import {useForm} from 'react-hook-form';
import Joi from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { parsePhoneNumberFromString } from 'libphonenumber-js';


function NewPma() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const navigate = useNavigate();
  const [areaCode, setAreaCode] = useState('');
  const [countries, setCountries] = useState([]);
  const [countriesToManage, setCountriesToManage] = useState([]);
  const [userRole, setUserRole] = useState(role);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(''); // Estado para el idioma seleccionado
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Esquema de validación con Joi
  const schema = Joi.object({
    name: Joi.string().required().messages({'string.empty': t('alert-name')}),
    last_name: Joi.string().required().messages({'string.empty': t('alert-last-name')}),
    email: Joi.string().email({tlds: {allow: false}}).required().messages({
      'string.email': t('alert-email-format'),
      'string.empty': t('alert-email'),
    }),
    prefix_phone: Joi.string().pattern(/^\+[0-9]{2,3}$/).required().messages({
      'string.pattern.base': t('alert-prefix'),
      'string.empty': t('alert-prefix'),
    }),
    phone: Joi.string().pattern(/^[0-9]{7,15}$/).required().messages({
      'string.pattern.base': t('alert-phone-format'),
      'string.empty': t('alert-phone'),
    }),
    area_code: Joi.string().required().messages({'string.empty': t('alert-country')}),
    city: Joi.string().required().messages({'string.empty': t('alert-city')}),
    preferred_language: Joi.string().required().messages({'string.empty': t('alert-language')}),
    role: Joi.string().required().messages({'string.empty': t('alert-role')}),
    managed_country: Joi.array().min(1).required().messages({
      'array.base': t('alert-country'),
      'array.min': t('alert-country'),
      'any.required': t('alert-country')
    }),
  });

  const {register, handleSubmit, formState, setValue, formData, setFocus} = useForm({
    resolver: joiResolver(schema),
    mode: 'all',
    defaultValues: {
      managed_country: [], // Inicializar como un array vacío
    },
  });
  const {errors} = formState;

  // Llamada para obtener los países y establecer el estado de countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
        const filteredAndSortedCountries = response.data
          .filter(country => country.area_code !== "EN") // Excluir "EN"
          .sort((a, b) =>
            t(`countries-${a.area_code}`).localeCompare(t(`countries-${b.area_code}`))
          );
        setCountries(filteredAndSortedCountries);
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };
    fetchCountries();
  }, [t]);


  // Actualiza la lista de idiomas cuando cambia el área_code seleccionado
  useEffect(() => {
    if (areaCode && countries.length) {
      const selectedCountry = countries.find((country) => country.area_code === areaCode);
      if (selectedCountry) {
        setLanguages(selectedCountry.language || []);
        setSelectedLanguage(''); // Reinicia el idioma seleccionado
        setValue('preferred_language', ''); // Reinicia el valor del select de idioma
      }
    }
  }, [areaCode, countries, setValue]);

  // Manejo del cambio en el select de país
  const handleCountryChange = (e) => {
    const selectedAreaCode = e.target.value;
    setAreaCode(selectedAreaCode);

    // Resetear idiomas y idioma seleccionado si se cambia el país
    if (!selectedAreaCode) {
      setLanguages([]);
      setSelectedLanguage('');
      setValue('preferred_language', '');
    }
  };

  // Manejo del envío de formulario con validación
  const onSubmit = async (data) => {
    // Validar el número de teléfono completo
    const fullPhoneNumber = parsePhoneNumberFromString(
      data.prefix_phone + data.phone
    );

    if (!fullPhoneNumber.isValid()) {
      setPhoneError(t('alert-phone-format'));
      setFocus('phone'); // Enfocar el campo de teléfono para la corrección
      return; // Detener el envío si el número no es válido
    } else {
      setPhoneError('');
    }

    // Si el número de teléfono es válido, procede con el envío
    const payload = {
      email: data.email,
      name: data.name,
      last_name: data.last_name,
      prefix_phone: data.prefix_phone,
      phone: data.phone,
      area_code: data.area_code,
      city: data.city,
      preferred_language: data.preferred_language,
      area_code_visibility: countriesToManage,
    };

    let url;
    if (data.role === 'pma') {
      url = `${process.env.REACT_APP_API_URL}/pma`;
    } else if (data.role === 'pmaGM') {
      url = `${process.env.REACT_APP_API_URL}/pma/general-manager`;
    }

    try {
      const response = await apiClient.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage(t('admin-new-pma-successful'));
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => navigate("/admin/main/pma"), 2000);
      }
    } catch (error) {
      if (error.response?.status === 409) {
        setSnackbarMessage(t('alert-duplicate'));
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };


  return (
    <>
      {token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main className="new-pma">
            <section className="form">
              <div className="inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Link to="/admin/main/pma" className="back-button">
                    <span className="icon-chevron-left"></span> {t('common-back')}
                  </Link>
                  <h2>
                    {t('admin-new-pma')}
                  </h2>
                  <div>
                    <p><strong>{t('common-basic-info')}</strong></p>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={5}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="name"
                            label={t('common-name')}
                            error={errors?.name}
                            {...register('name')}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={6} md={5}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="last_name"
                            label={t('common-last-name')}
                            error={errors?.last_name}
                            {...register('last_name')}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="email"
                            label={t('common-email')}
                            error={errors?.email}
                            {...register('email')}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={12} md={6}>
                        <div>
                          <Grid container spacing={1}>
                            <Grid xs={4} sm={4} md={4}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="prefix_phone"
                                  label={t('common-prefix')}
                                  placeholder="+xx/+xxx"
                                  error={errors?.prefix_phone}
                                  {...register('prefix_phone')}
                                />
                              </div>
                            </Grid>
                            <Grid xs={8} sm={8} md={8}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="phone"
                                  label={t('common-phone')}
                                  error={errors?.phone}
                                  {...register('phone')}
                                />
                                {phoneError && (
                                  <p className="error-message">{phoneError}</p>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={1} md={6}>
                        <div className="form-control required">
                          <label htmlFor="area_code">{t('common-country')}</label>
                          <select
                            name="area_code"
                            id="area_code"
                            className="normal"
                            {...register('area_code')}
                            onChange={handleCountryChange}
                          >
                            <option value="">{t('common-select-country')}</option>
                            {countries.map((country) => (
                              <option key={country.area_code} value={country.area_code}>
                                {t(`countries-${country.area_code}`)}
                              </option>
                            ))}
                          </select>
                          {errors.area_code && (
                            <span className="input__error">{errors.area_code.message}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} sm={1} md={6}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="city"
                            label={t('common-city')}
                            error={errors?.city}
                            {...register('city')}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={1} md={6}>
                        <div className="form-control required">
                          <label htmlFor="preferred_language">{t('common-select-language')}</label>
                          <select
                            name="preferred_language"
                            id="preferred_language"
                            className="normal"
                            {...register('preferred_language')}
                            value={selectedLanguage} // Reinicia el idioma seleccionado
                            onChange={(e) => setSelectedLanguage(e.target.value)} // Actualiza el idioma seleccionado
                          >
                            <option value="">{t('common-select-language')}</option>
                            {languages.map((language, index) => (
                              <option key={index} value={language}>
                                {t(`languages-${language}`)}
                              </option>
                            ))}
                          </select>
                          {errors.preferred_language && (
                            <span className="input__error">{errors.preferred_language.message}</span>
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <br/><br/>
                    <p><strong>{t('common-settings-pma')}</strong></p>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <label htmlFor="role">{t('common-admin-role')}</label>
                          <select
                            name="role"
                            id="role"
                            className="normal"
                            {...register('role')}
                            /*onChange={handleRoleChange}*/
                          >
                            <option value="">{t('common-select-role-admin')}</option>
                            <option value="pmaGM">{t('common-role-pmaGM')}</option>
                            <option value="pma">{t('common-role-pma')}</option>
                          </select>
                          {errors.role && (
                            <span className="input__error">{errors.role.message}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <label htmlFor="managed_country">{t('common-manage-country')}</label>
                          <FormControl fullWidth>
                            <Select
                              id="managed_country"
                              className="multiselect"
                              variant="filled"
                              multiple
                              value={countriesToManage}
                              onChange={(e) => {
                                const {value} = e.target;
                                setCountriesToManage(Array.isArray(value) ? value : [value]); // Actualiza el estado
                                setValue('managed_country', Array.isArray(value) ? value : [value]);
                              }}
                              input={
                                <InputBase
                                  name="managed_country"
                                  id="managed_country"
                                  label={t('common-manage-country')}
                                />
                              }
                              renderValue={(selected) => selected.join(', ')}
                            >
                              {countries.map((country) => (
                                <MenuItem key={country.area_code} value={country.area_code} className="multiselect-options">
                                  <Checkbox checked={countriesToManage.includes(country.area_code)} />
                                  <ListItemText primary={t(`countries-${country.area_code}`)} />
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.managed_country && (
                              <span className="input__error">{errors.managed_country.message}</span>
                            )}
                          </FormControl>
                        </div>
                      </Grid>

                    </Grid>

                    <Grid container spacing={3} >
                      <Grid xs={12} sm={6} md={6}>
                        <button className="btn" type="submit">
                          {t('admin-new-pma-action')}
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </div>
            </section>
          </main>
          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarSeverity} sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default NewPma;
