import './new-retailer.scss';
import Header from '../../../organisms/header/header.js';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import { Alert, Snackbar } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Input from "../../../atoms/input";
import apiClient from "../../../../utils/apiClient";
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function NewRetailer() {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const [countries, setCountries] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState('');

  // Definir el esquema de validación con Joi
  const schema = Joi.object({
    name: Joi.string().max(50).required().messages({ 'string.empty': t('alert-name') }),
    retailer_code: Joi.string().max(50).required().messages({ 'string.empty': t('alert-retailer-code') }),
    email: Joi.string().email({ tlds: { allow: false } }).required().messages({
      'string.email': t('alert-email-format'),
      'string.empty': t('alert-email')
    }),
    prefix_phone: Joi.string().pattern(/^\+[0-9]{2,3}$/).required().messages({
      'string.pattern.base': t('alert-prefix-format'),
      'string.empty': t('alert-prefix')
    }),
    phone: Joi.string().pattern(/^[0-9]{7,15}$/).required().messages({
      'string.pattern.base': t('alert-phone-format'),
      'string.empty': t('alert-phone')
    }),
    address_line_1: Joi.string().max(150).required().messages({
      'string.empty': t('alert-street-address'),
      'string.max': t('alert-address-max')
    }),
    city: Joi.string().required().messages({ 'string.empty': t('alert-city') }),
    area_code: Joi.string().required().messages({ 'string.empty': t('alert-country') }),
  });

  // Inicializar react-hook-form con Joi
  const { register, handleSubmit, formState, formData, setFocus} = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });
  const { errors } = formState;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
        setCountries(response.data);
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarOpen(true);
      }
    };
    fetchCountries();
  }, [t]);

  const onSubmit = async (data) => {
   //console.log(data)
    try {

      const fullPhoneNumber = parsePhoneNumberFromString(
        data.prefix_phone + data.phone
      );

      if (!fullPhoneNumber.isValid()) {
        setPhoneError(t('alert-phone-format'));
        setFocus('phone');
        return;
      } else {
        setPhoneError('');
      }

      const payload = {
        retailer_code: data.retailer_code,
        name: data.name,
        email: data.email,
        prefix_phone: data.prefix_phone,
        phone: data.phone,
        address: data.address_line_1,
        city: data.city,
        area_code: data.area_code,
      };

      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/retailer`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage(t('feedback-retialer-created'));
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => navigate("/admin/main/retailer"), 2000);
      }
    } catch (error) {
      if (error.response?.status === 409) {
        setSnackbarMessage(t('alert-duplicate'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <>
      {token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Link to="/admin/main/retailer" className="back-button">
                    <span className="icon-chevron-left"></span> {t('common-back')}
                  </Link>
                  <h2>
                    {t('admin-new-retailer-title')}
                    <small>{t('admin-new-retailer-text')}</small>
                  </h2>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={6} md={8}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="name"
                          label={t('common-name')}
                          error={errors?.name}
                          {...register('name')}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={6} md={4}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="retailer_code"
                          label={t('common-retailer-code')}
                          error={errors?.retailer_code}
                          {...register('retailer_code')}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={12} md={6}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="email"
                          label={t('common-email')}
                          error={errors?.email}
                          {...register('email')}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <div>
                        <Grid container spacing={1}>
                          <Grid xs={4} sm={4} md={4}>
                            <div className="form-control required">
                              <Input
                                type="text"
                                id="prefix_phone"
                                placeholder="+xx/+xxx"
                                label={t('common-prefix')}
                                error={errors?.prefix_phone}
                                {...register('prefix_phone')}
                              />
                            </div>
                          </Grid>
                          <Grid xs={8} sm={8} md={8}>
                            <div className="form-control required">
                              <Input
                                type="text"
                                id="phone"
                                label={t('common-phone')}
                                error={errors?.phone}
                                {...register('phone')}
                              />
                              {phoneError && (
                                <p className="error-message">{phoneError}</p>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={8} sm={8} md={12}>
                      <div className="form-control required">
                        <Input
                          maxLength="150"
                          type="text"
                          id="address_line_1"
                          label={t('common-address')}
                          error={errors?.address_line_1}
                          {...register('address_line_1')}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid xs={12} sm={1} md={6}>
                      <div className="form-control required">
                        <label htmlFor="country">{t('common-country')}</label>
                        <select
                          name="area_code"
                          id="country"
                          className="normal"
                          {...register('area_code')}
                        >
                          <option key="" value="">{t('common-select-country')}</option>
                          {countries.map((country) => (
                            <option key={country.area_code} value={country.area_code}>
                              {t('countries-' + country.area_code)}
                            </option>
                          ))}
                        </select>
                        {errors.area_code && <span className="input__error">{errors.area_code.message}</span>}
                      </div>
                    </Grid>
                    <Grid xs={12} sm={4} md={6}>
                      <div className="form-control required">
                        <Input
                          maxLength="50"
                          type="text"
                          id="city"
                          label={t('common-city')}
                          error={errors?.city}
                          {...register('city')}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid xs={12} sm={6} md={6}>
                      <button className="btn" type="submit">
                        {t('admin-new-retailer-action')}
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default NewRetailer;
