import './modal-create-retailerGM.scss';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Snackbar } from '@mui/material';
import { Box } from '@mui/material';
import apiClient from '../../../../utils/apiClient';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


function ModalCreateRetailerGM({ open, onClose, generalGMData }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const navigate = useNavigate();

  const onSubmit = async () => {
    const payload = {
      "email": generalGMData.email,
      "name": generalGMData.name,
      "last_name": generalGMData.last_name,
      "prefix_phone": generalGMData.prefix_phone,
      "phone": generalGMData.phone,
      "preferred_language": generalGMData.preferred_language,
      "ids_retailer": generalGMData.ids_retailer
    }
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/retailer/retailer-general-manager`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      // Si la solicitud fue exitosa (status 200 o 201)
      if (response.status === 200) {
        setSnackbarMessage(t('admin-new-retailerGM-successful'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        // Hacer algo después de la respuesta exitosa, como redirigir o limpiar el formulario
      }

    } catch (error) {
      // Manejo de errores
     //console.log(error.response.status);
      if (error.response) {
        if (error.response.status === 400) {
          console.error("Solicitud incorrecta: ", error.response.data);
        } else if (error.response.status === 409) {
          setSnackbarMessage(t('alert-duplicate'));
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          console.error("Error inesperado: ", error);
          // Mostrar el mensaje de error en el snackbar
          setSnackbarMessage(t('common-internal-server-error'));
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }

      }
    }
  };



  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-confirm-retailerGM"
      >
        <Box className="box-confirmation">
          <button className="close" onClick={onClose}>
            <span className="icon-close"></span>
          </button>
          <div className="box-content">
            <h2>{t('admin-new-retailerGM-modal-title')}</h2>
            <p>{t('admin-new-retailerGM-modal-retailer-text') + ':'}<br/> <strong>{generalGMData.retailer_name}</strong></p>
            <p><strong>{generalGMData.name} {generalGMData.last_name}</strong><br/>{generalGMData.email}</p>
            <div className="modal-buttons">
              <Button variant="contained" className="btn button-mod cancel-mod" onClick={onClose}>
                {t('common-cancel')}
              </Button>
              <Button variant="contained" className="btn button-mod confirm-mod" onClick={onSubmit}>
                {t('modal-confirm-nomination')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalCreateRetailerGM;
