import './unenroll.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useLocation, useParams} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from '../../../../utils/apiClient';
import ModalPmaConfirmUnenroll from '../../../organisms/role-pma/modal-pma-confirm-unenroll/modal-pma-confirm-unenroll';

function Unenroll() {
  const { t } = useTranslation();
  const { hacId } = useParams();

  const [hacData, setHacData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [textareaContent, setTextareaContent] = useState('');
  const [textareaError, setTextareaError] = useState(false);

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  useEffect(() => {
    const fetchHacData = async () => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/pma/hac?hacId=${hacId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHacData(response.data);
      } catch (err) {}
    };

    if (hacId) {
      fetchHacData();
    }
  }, [hacId, token]);

  const handleOpenModal = () => {
    if (textareaContent.trim() === '') {
      setTextareaError(true);
    } else {
      setTextareaError(false);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTextareaChange = (event) => {
    setTextareaContent(event.target.value);
    if (event.target.value.trim() !== '') {
      setTextareaError(false);
    }
  };

  return (
    <>
      {token &&
      (role === "pma" || role === 'pmaGM') ? (
        <div className="App">
          <Header />
          <main className="unenroll">
            <section className="unenroll">
              <div className="inner">
                <Link to="/pma/main/" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')} / {breadcrumbName}
                </Link>
                <h2>{t('pma-unenroll-title')}</h2>
                <p>{t('pma-unenroll-text')}</p>
                <div className="data">
                  <strong>
                    {hacData
                      ? `${hacData.name} ${hacData.last_name}`
                      : 'Nombre Apellido'}
                  </strong>
                  <span>{hacData ? hacData.email : 'email@example.com'}</span>
                </div>
                <div
                  className={`form-control required ${
                    textareaError ? 'error' : ''
                  }`}
                >
                  <label>{t('pma-unenroll-label')}</label>
                  <textarea
                    className="txt-area"
                    onChange={handleTextareaChange}
                  ></textarea>
                  {textareaError && (
                    <span className="error-message">
                      {t('pma-unenroll-error')}
                    </span>
                  )}
                </div>
                <p>{t('pma-unenroll-warning')}</p>
                <div className="actions">
                  <button className="btn fit" onClick={handleOpenModal}>
                    {t('pma-unenroll-action')}
                  </button>
                </div>
              </div>
            </section>
          </main>
          <ModalPmaConfirmUnenroll
            open={openModal}
            handleClose={handleCloseModal}
            hacId={hacId}
            textareaContent={textareaContent}
          />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default Unenroll;
