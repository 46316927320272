import './retailer-details.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from "../../../../utils/apiClient";
import {Alert, Snackbar} from '@mui/material';
import ModalUnassignRetailerGM from "../../../organisms/role-admin/modal-unassign-retailerGM/modal-unassign-retailerGM";

function RetailerDetails() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const {retailerId} = useParams();
  const navigate = useNavigate();
  const [areaCode, setAreaCode] = useState('');

  const [contactInfo, setContactInfo] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  // Inicializar el estado del snackbar en false (lo mantenemos cerrado al inicio)
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [generalGMData, setGeneralGMData] = useState({}); // Inicializado como objeto vacío

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/retailer?retailerId=${retailerId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setContactInfo(response.data);
          setGeneralGMData(response.data.retailer_gm);
          setAreaCode(response.data.area_code);
        }
      } catch (error) {
        // Solo abre el snackbar si ocurre un error real
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          setSnackbarMessage(t('common-internal-server-error'));
        }
      }
    };

    fetchData();
  }, [retailerId, token, t]);

  const handleOpenModal = (gmData) => {
    if (gmData && gmData.name) { // Verifica si los datos son válidos y contienen 'name'
      setGeneralGMData(gmData); // Establecer los datos del GM
      setOpenModal(true); // Abrir la modal
    } else {
     //console.log('GM Data is invalid or empty'); // Si no hay datos válidos, se muestra un log
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Cerrar la modal
  };

  return (
    <>
      {token &&
      (role === 'admin' ||
        role === 'marketAdmin' ||
        role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main>
            <section className="contact-info admin">
              <div className="inner">
                <div className="back-button">
                  <Link to="/admin/main/retailer" className="back-button">
                    <span className="icon-chevron-left"></span>
                    {' '}
                    {t('common-back')}
                  </Link>
                  {' '} / {contactInfo?.retailer.name}
                </div>
                <h2>{t('retailer-info-title')}</h2>
                {contactInfo ? (
                  <>
                    <div className="retailer">
                      <h5>{t('retailer-info-title')}</h5>
                      <dl>
                        <div>
                          <dt>{t('common-name')}</dt>
                          <dd>{contactInfo.retailer.name}</dd>
                        </div>
                        <div>
                          <dt>{t('common-country')}</dt>
                          <dd>{t(`countries-${contactInfo.retailer.area_code}`)}</dd>
                        </div>
                        <div>
                          <dt>{t('common-city')}</dt>
                          <dd>{contactInfo.retailer.city}</dd>
                        </div>
                        <div>
                          <dt>{t('common-retailer-code')}</dt>
                          <dd>{contactInfo.retailer.retailer_code}</dd>
                        </div>
                      </dl>
                    </div>

                    <div className="actions">
                      {!contactInfo.retailer_gm.id && (
                        <button
                          onClick={() => {
                            navigate(`/admin/new-retailerGM/${retailerId}`, {
                              state: {
                                areaCode: areaCode,
                                jlr_code: contactInfo.retailer.retailer_code,
                              },
                            });
                          }}
                          className="btn secondary"
                        >
                          <span className="icon-plus"></span> {t('admin-new-retailerGM-action')}
                        </button>
                      )}
                      <button
                        onClick={() =>
                          navigate(`/admin/new-saleperson/${retailerId}`, {
                            state: {
                              areaCode: areaCode,
                              retailerName: contactInfo.retailer.name,
                            },
                          })
                        }
                        className="btn secondary"
                      >
                        <span className="icon-plus"></span> {t('admin-new-saleperson-action')}
                      </button>


                    </div>

                    {contactInfo.retailer_gm.name && (
                      <div>
                        <h5>{t('retailer-info-title-retailergm')}</h5>
                        <div className="person">

                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>
                                {contactInfo.retailer_gm.name}{' '}
                                {contactInfo.retailer_gm.last_name}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>{contactInfo.retailer_gm.email}</dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.retailer_gm.prefix_phone}{' '}
                                {contactInfo.retailer_gm.phone}
                              </dd>
                            </div>
                          </dl>
                          <div className="actions">
                            <button
                              onClick={() =>
                                navigate(`/admin/edit-retailerGM/${retailerId}`, {
                                  state: {
                                    areaCode: areaCode,
                                    retailerGMEmail: contactInfo.retailer_gm.email,
                                  },
                                })
                              }
                              className="btn secondary"
                            >
                              {t('retailer-info-action1')}
                            </button>
                            <button
                              onClick={() => handleOpenModal(contactInfo.retailer_gm)}
                              className="btn secondary"
                            >
                              {t('retailer-info-action4')}
                            </button>
                            <button
                              onClick={() => navigate(`/admin/delete-user`, {
                                state: {
                                  user: contactInfo.retailer_gm,
                                  role: 'retailerGM',
                                  retailer_id: retailerId,
                                  retailer_name: contactInfo.retailer.name
                                },
                              })}
                              className="btn text"
                            >
                              {t('admin-main-tab2-option3')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {contactInfo.sales_person &&
                      contactInfo.sales_person.length > 0 && (
                        <>
                          <h5>{t('retailer-info-title-saleperson1')}</h5>

                          {contactInfo.sales_person.map((person) => (
                            <div className="person">
                              <dl key={person.id}>
                                <div>
                                  <dt>{t('common-name')}</dt>
                                  <dd>{`${person.name} ${person.last_name}`}</dd>
                                </div>
                                <div>
                                  <dt>{t('common-email')}</dt>
                                  <dd>{person.email}</dd>
                                </div>
                                <div>
                                  <dt>{t('common-phone')}</dt>
                                  <dd>{`${person.prefix_phone} ${person.phone}`}</dd>
                                </div>
                              </dl>
                              <div className="actions">
                                <button
                                  onClick={() =>
                                    navigate(`/admin/edit-saleperson/${retailerId}`, {
                                      state: {
                                        user: person,
                                        role: 'saleperson',
                                        retailer_id: retailerId,
                                        retailer_name: contactInfo.retailer.name
                                      },
                                    })
                                  }
                                  className="btn secondary"
                                >
                                  {t('retailer-info-action1')}
                                </button>
                                <button
                                  onClick={() => navigate(`/admin/delete-user`, {
                                    state: {
                                      user: person,
                                      role: 'saleperson',
                                      retailer_id: retailerId,
                                      retailer_name: contactInfo.retailer.name
                                    },
                                  })}
                                  className="btn text"
                                >
                                  {t('admin-main-tab2-option3')}
                                </button>
                              </div>
                            </div>
                          ))}

                        </>
                      )}
                  </>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity={snackbarSeverity} sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <ModalUnassignRetailerGM
            open={openModal}
            onClose={handleCloseModal}
            generalGMData={generalGMData}
            retailerId={retailerId}
          />
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default RetailerDetails;
