import './table-hac.scss';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import decodeToken from '../../../../utils/decode-token';
import ModalNominateOnBehalf from '../modal-nominate-on-behalf/modal-nominate-on-behalf';
import { Alert, Snackbar } from '@mui/material';

function TableHac({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const userId = decodeToken(token).id;
  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser') || null);
  const canNominate = loggedUser?.nominate;


  const [hacs, setHacs] = useState([]);
  const [filteredHacs, setFilteredHacs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [markets] = useState([
    { name: 'AU_CZ_SK', countries: ['AT', 'CZ', 'SK'] },
    { name: 'Benelux', countries: ['BE', 'LUX', 'NE'] },
    { name: 'France', countries: ['FR'] },
    { name: 'Germany', countries: ['DE'] },
    { name: 'Iberia', countries: ['ES', 'PT'] },
    { name: 'Italy', countries: ['IT'] },
    { name: 'Switzerland', countries: ['CH'] },
    { name: 'Turkey', countries: ['TR'] },
  ]);

  //const [associatedMarket, setAssociatedMarket] = useState('');
  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
    status: '',
  });
  const [anchorEl, setAnchorEl] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        let url = '';
        if (
          role === 'admin' ||
          role === 'marketAdmin' ||
          role === 'countryAdmin'
        ) {
          url = `${process.env.REACT_APP_API_URL}/admin/${userId}`;
        }

        if (!url) {
          console.error('No se pudo construir la URL para la solicitud.');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        if (role === 'marketAdmin') {
          const response = await apiClient.get(url, config);
          if (response.data && response.data.market_area_code) {
            //setAssociatedMarket(response.data.market_area_code);
          } else {
            console.warn('No se encontró market_area_code en la respuesta.');
          }
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    };

    fetchData();
  }, [token, userId, role]);

  const fetchData = async () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/admin/hacs`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.get(url, config);
      if (response.status === 200) {
        const sortedHacs = response.data.sort((a, b) => {
          const [dayA, monthA, yearA] = a.last_update.split('-').map(Number);
          const [dayB, monthB, yearB] = b.last_update.split('-').map(Number);
          const dateA = new Date(yearA, monthA - 1, dayA);
          const dateB = new Date(yearB, monthB - 1, dayB);
          return dateB - dateA;
        });

        // Recuperar filtros almacenados y aplicar si existen
        const storedFilters = JSON.parse(sessionStorage.getItem('admin-hac-filters') || '{}');
        const initialFilters = {
          ...filters,
          ...storedFilters,
        };
        setFilters(initialFilters);
        setHacs(sortedHacs);
        applyFilters(initialFilters, sortedHacs); // Aplicar los filtros guardados al cargar
        setDataLoaded(true);
        onDataLoaded();

        const market = markets.find((m) => m.name === initialFilters.market);
        const availableCountries = market ? market.countries : [...new Set(response.data.map((hac) => hac.area_code))];
        setCountries(availableCountries);
      }
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    }
  };

  useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);
      const updatedCountries = selectedMarket ? selectedMarket.countries : [...new Set(hacs.map((hac) => hac.area_code))];
      setCountries(updatedCountries);
      setFilters({
        ...filters,
        market: value,
        country: '', // Resetea el país al cambiar el mercado para forzar al usuario a elegir un nuevo país del mercado
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = (appliedFilters = filters, hacsData = hacs) => {
    let filtered = [...hacsData];
    if (appliedFilters.name) {
      filtered = filtered.filter(
        (hac) =>
          hac.name.toLowerCase().includes(appliedFilters.name.toLowerCase()) ||
          hac.last_name.toLowerCase().includes(appliedFilters.name.toLowerCase())
      );
    }

    if (appliedFilters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === appliedFilters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((hac) =>
          selectedMarket.countries.includes(hac.area_code)
        );
      }
    }

    if (appliedFilters.country) {
      filtered = filtered.filter((hac) => hac.area_code === appliedFilters.country);
    }

    if (appliedFilters.status) {
      filtered = filtered.filter((hac) => hac.status === appliedFilters.status);
    }

    const uniqueFiltered = Array.from(new Map(filtered.map(item => [item.id, item])).values()); // fix para cuando llegan duplicados.
    setFilteredHacs(uniqueFiltered);
    sessionStorage.setItem('admin-hac-filters', JSON.stringify(appliedFilters));
  };

  const handleApplyFilter = () => {
    applyFilters();
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <header>
        <h2>{t('admin-main-tab1-title')}</h2>
        {canNominate ? (
          <button className="btn secondary" onClick={handleOpenModal}>
            <span className="icon-plus"></span> {t('admin-main-tab1-action')}
          </button>
        ): (
          null
        )}
      </header>
      <ModalNominateOnBehalf open={openModal} onClose={handleCloseModal} />
      {dataLoaded ? (
        hacs && hacs.length !== 0 ? (
          <>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={3}>
                  <div className="form-control">
                    <label htmlFor="name">
                      {t('common-name')}/{t('common-last-name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                {(role === 'admin' || role === 'marketAdmin') && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets.map((market) => (
                          <option key={market.name} value={market.name}>
                            {t(`market-${market.name}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                {(role === 'admin' || role === 'marketAdmin') && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {t(`countries-${country}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="status">{t('common-status')}</label>
                    <select
                      name="status"
                      id="status"
                      className="normal"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-status')}</option>
                      <option value="nominated">{t('common-nominated')}</option>
                      <option value="active">{t('common-active')}</option>
                      <option value="exited">{t('common-exited')}</option>
                    </select>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <div className="actions">
                    <button className="btn" onClick={handleApplyFilter}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
              </Grid>
              <div className="summary">
                <strong>
                  {t('common-total-results')}: <span>{filteredHacs.length}</span>
                </strong>{' '}
                |
                <span>
                {t('common-users-active')}:{' '}
                  <span>
                  {filteredHacs.filter((hac) => hac.status === 'active').length}
                </span>
              </span>{' '}
                |
                <span>
                {t('common-users-nominated')}:{' '}
                  <span>
                  {
                    filteredHacs.filter((hac) => hac.status === 'nominated')
                      .length
                  }
                </span>
              </span>
              </div>
            </div>

            <div className="table-wrapper">
              {filteredHacs.length !== 0 ? (
                <Table aria-label="simple table" className="user-table admin-hac">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"><span className="text">{t('common-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-last-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-role-pma')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-retailer')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-market')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-country')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-status')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-last-update')}</span></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredHacs.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left"><span className="text">{row.name}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.last_name}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.first_pma_email}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.retailer}</span></TableCell>
                        <TableCell align="left"><span className="text">{t(`market-${row.market}`)}</span></TableCell>
                        <TableCell align="left"><span className="text">{t(`countries-${row.area_code}`)}</span></TableCell>
                        <TableCell align="left"><span className="text">{t(`common-${row.status}`)}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.last_update}</span></TableCell>
                        <TableCell align="right">
                          {row.status && row.status !== 'exited' ? (
                            <>
                              <span className="icon-more" onClick={handleClick(row.id)}></span>
                              <Menu
                                className="submenu"
                                anchorEl={anchorEl[row.id]}
                                open={Boolean(anchorEl[row.id])}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => navigate(`/admin/preferences/${row.id}`, {
                                    state: {
                                      breadcrumbName: row.name + ' ' + row.last_name
                                    }
                                  })}
                                >
                                  {t('pma-hac-tab-option3')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => navigate(`/admin/nominating-retailer/${row.id}`, {
                                    state: {
                                      breadcrumbName: row.name + ' ' + row.last_name
                                    }
                                  })}
                                >
                                  {t('admin-main-tab1-option4')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => navigate(`/admin/assigned-pmas/${row.id}`, {
                                    state: {
                                      breadcrumbName: row.name + ' ' + row.last_name
                                    }
                                  })}
                                >
                                  {t('admin-main-tab1-option5')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => navigate(`/admin/shared-notes/${row.id}`, {
                                    state: {
                                      breadcrumbName: row.name + ' ' + row.last_name
                                    }
                                  })}
                                >
                                  {t('pma-hac-tab-option7')}
                                </MenuItem>
                              </Menu>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="no-result">
                  <h4 className="txt-center">{t('admin-main-title-empty')}</h4>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('admin-main-tab1-title-empty')}
              <small>{t('admin-main-tab1-text-empty')}</small>
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHac;
